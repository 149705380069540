// 遗产要素单体或局部的测绘图
import SHZT from './sn-table-column-shzt';
import DJZT from './sn-table-column-djzt';

const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [
                {
                    prop: 'YCYSBM',
                    label: '遗产要素'
                }, {
                    prop: 'XZPG',
                    label: '遗产要素现状评估',
                    transList: {
                        0: '正面影响',
                        1: '兼有正、负影响',
                        2: '负面影响',
                        3: '无影响'
                    }
                }, {
                    prop: 'BCXZ',
                    label: '遗产要素保存现状',
                }, {
                    prop: 'RKSJ',
                    label: '时间',
                    substr: true
                },
                SHZT,
                DJZT
            ],
            // 操作列
            columnsBtn: {
                buttonData: []
            }
        },
    },
    snButton: {
        buttonData: [
            {
                isShow: true,
                btnType: 'button',
                operateType: 'buttonNew',
                showMethod: 'showNewBtn',
                name: '新建',
                round: true,
                backColor: '#28ccd9',
            },
            {
                btnType: 'button',
                operateType: 'buttonExport',
                showMethod: 'showExportBtn',
                name: '导出',
                round: true,
                backColor: '#ffcc66',
                color: '#fff'
            }
        ]
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
        searchBtn: {
            buttonData: [{
                btnType: 'button',
                operateType: 'searchBtn',
                name: '查询',
                round: true,
                backColor: '#2a63d5',
                color: '#fff'
            }]
        },
        selectData: []
    },
};
export default selfData;